import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic8.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic9.jpeg",
];

export default function InternationalTigerDay() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">International Tiger Day</h1>

        <p>
          <strong>
            A TIGER IS A SYMBOL OF BEAUTY, BRAVERY, AND STRENGTH. SAVE TIGER,
            SAVE THE NATION'S PRIDE.
          </strong>
          <br />
          <br />
          The International Tiger Day, was celebrated by the Pre-Primary
          learners of Chinmaya Vidyalaya, New Delhi on 28th July 2023. The
          learners were shown a video to explain tiger conservation and did free
          hand drawing of tiger, a skit on the topic-" Save The Tiger" was
          performed by the learners to create awareness among the learners about
          protection and conservation of this beautiful species.The facilitators
          and learners wore different shades of orange.The enthusiasm of the
          learners was overwhelming and they also became aware of this noble
          cause.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
